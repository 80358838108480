/* Generic CSS utility classes */
.fontWeightNormal {
  font-weight: normal;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.fontWeightBold {
  font-weight: bold;
}

.textUnderline {
  text-decoration: underline;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.blue-line-top-border {
  border-top: 3px blue solid;
}

.blue-line-bottom-border {
  border-bottom: 3px blue solid;
}

.border-bottom {
  border-bottom: solid darkgrey .5px;
}

.supplierOrderNo {
  width: 125px;
}

/* pageSupplierPO classes */
.Link {
  color: blue;
  text-decoration-color: blue !important;
}

a:visited {
  color: blue;
}

#pageSupplierPO {
  padding-bottom: 10px;
}

.downloadIcon {
  color: blue;
}

#pageSupplierPO .contentBox {
  position: relative;
}

#pageSupplierPO .customDatePicker-outer {
  height: 10px;
  display: inline-block;
}

#pageSupplierPO .customDatePicker {
  border-radius: 2px;
  border: 1px solid var(--content-border-color);
  width: 80px;
  padding: 2px;
}

#pageSupplierPO .spox_help-outer {
  position: absolute;
  right: 0px;
}

#pageSupplierPO .spox_help-inner {
  position: relative;
  right: 46px;
  font-size: 14px;
}

#pageSupplierPO .ackChecklist .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

#pageSupplierPO .ackChecklist .flex {
  display: flex;
  justify-content: flex-start;
  border-bottom: solid darkgrey .5px;
  border-top: solid darkgrey .5px;
  padding: 10px;
}

#pageSupplierPO .ackChecklist .flex .card {
  align-self: center;
  display: flex;
  align-items: center;
  opacity: 0.3;
  font-size: 14px;
  padding-right: 15px;
}

#pageSupplierPO .ackChecklist .flex .card.complete {
  opacity: 1;
}

#pageSupplierPO .ackChecklist #downloadIcon .fa {
  font-size: 16px;
  margin-left: 5px;
}

#pageSupplierPO .ackChecklist #downloadIcon .far {
  font-size: 16px;
  margin-left: 5px;
}

#pageSupplierPO .ackChecklist .fa {
  font-size: 30px;
  margin-right: 10px;
}

#pageSupplierPO .ackChecklist .far {
  font-size: 30px;
  margin-right: 10px;
}

#pageSupplierPO .ackChecklist .flex .card.complete .far {
  color: green;
}

#pageSupplierPO .flex .card .cardTitle {
  margin-bottom: 5px;
  font-size: 14px;
  text-decoration: underline;
}

/* PO HEADER COMPONENTS */
#pageSupplierPO .poHDR .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

#pageSupplierPO .poHDR .subTitle {
  margin-bottom: 10px;
}

#pageSupplierPO .poHDR .flex {
  display: flex;
}

#pageSupplierPO .poHDR .flex .card {
  flex: 23%;
}

#pageSupplierPO .poHDR .flex .card .name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

#pageSupplierPO .poHDR .flex .card .partnerID {
  font-size: 12px;
  margin-bottom: 15px;
}

#pageSupplierPO .poHDR .flex .card .status-outer {
  line-height: 1.4em;
  border: 1px solid rgba(0, 0, 255, 0.05);
  background: var(--table-odd-row-color);
  padding: 10px;
  border-radius: 5px;
}

.card {
  line-height: var(--line-height);
}

/* PO Shipping Details */
#pageSupplierPO .poHdrShipTo .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

#pageSupplierPO .poHdrShipTo .flex {
  display: flex;
  justify-content: space-evenly;
}

#pageSupplierPO .poHdrShipTo .flex .card {
  flex: 48%;
  padding-right: 15px;
}

/* Conditions & Instructions Components */
#pageSupplierPO #conditions {
  background: var(--table-odd-row-color);
}

#pageSupplierPO .conditions .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

#pageSupplierPO .conditions .sectionTitle {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
}

#pageSupplierPO .conditions .sectionText {
  margin-bottom: 15px;
  line-height: 1.4em;
}

#pageSupplierPO .conditions .termsAgree {
  margin-bottom: 20px;
}

#pageSupplierPO .conditions .shippingInstructions {
  border: 1px solid var(--content-border-color);
  width: 100%;
  max-width: 100%;
  height: 100px;
}

/* Items Components */

#pageSupplierPO .ackLineOptions {
  margin-bottom: 5px;
}

#pageSupplierPO .items .overall-lines-flex {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}

#pageSupplierPO .items .outer-flex {
  display: flex;
  justify-content: space-between;
}

#pageSupplierPO .items .outer-flex .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

#pageSupplierPO .filters-outer {
  padding-top: 10px;
  font-size: 14px;
  position: relative;
  right: -5px;
  margin-top: 5px;
}

#pageSupplierPO .filters-outer span {
  padding: 0px 8px;
  color: firebrick;
}

/* Item Components */
#pageSupplierPO .items .po-item {
  padding: 17px;
  position: relative;
}

#pageSupplierPO .items .canceledItem {
  opacity: 0.4;
}

#pageSupplierPO .items .po-item {
  border-top: solid 2px darkgray;
}

#pageSupplierPO .items .po-item .line-no-outer {
  background: var(--primary-color);
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  height: 22px;
  width: 85px;
  top: 85px;
  left: -22px;
  text-align: right;
  color: var(--bg-color);
  padding: 3px 5px;
}

#pageSupplierPO .items .po-item .po-item-hdr {
  padding-bottom: 5px;
  /* border-bottom: 1px solid var(--text-color); */
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

#pageSupplierPO .items .po-item .po-item-hdr .itemName-outer {
  line-height: 1.6em;
}

#pageSupplierPO .items .po-item .po-item-hdr .itemName {
  font-size: 16px;
  font-weight: bold;
}

#pageSupplierPO .items .po-item .po-item-hdr .itemStatus {
  color: firebrick;
}

#pageSupplierPO .items .po-item .po-item-cards {
  display: flex;
  justify-content: space-evenly;
}

#pageSupplierPO .items .po-item .po-item-cards .i-card {
  flex: 30%;
  line-height: 2em;
}

#pageSupplierPO .items .po-item .po-item-cards .i-card h4 {
  margin-bottom: 5px;
  font-size: 15px;
}

#pageSupplierPO .items .po-item .po-item-cards .i-card .smallerLines {
  line-height: 1.5em;
}

#del_pageSupplierPO .items .po-item .po-item-cards .i-card .shippingInfo {
  opacity: 0.5;
}

#del_pageSupplierPO .items .po-item .po-item-cards .i-card .shippingInfo.active {
  opacity: 1;
}

#del_pageSupplierPO .items .po-item .po-item-cards .i-card .shippingInfo textarea {
  width: 100%;
  height: 65px;
  background: var(--bg-color);
}

#pageSupplierPO .items .po-item .po-item-cards .confirmTextLink-small {
  color: blue;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

#poLineTable th {
  font-weight: normal;
  text-decoration: underline;
}

#poLineTable .itemDescription {
  width: 35%;
  word-wrap: break-word;
}

#poLineTable table,
th,
td {
  border-collapse: collapse;
  text-align: left;
  padding-right: 20px;
  vertical-align: top;
  line-height: var(--line-height);
}

/* Tracking information at the line level */
#pageSupplierPO .po-item .po-item-track-section {}

#pageSupplierPO .po-item .po-item-track-section .sectionTitle {
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 5px;
}

#pageSupplierPO .po-item .po-item-track-section .po-item-track-inner {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#pageSupplierPO .po-item .po-item-track-section .po-item-track-inner .trackingCard {
  flex: 30% 1;
  border: 1px solid rgba(0, 0, 255, 0.05);
  background: var(--table-odd-row-color);
  padding: 3px;
  border-radius: 5px;
  line-height: 1.6em;
  margin: 5px;
  position: relative;
}

#pageSupplierPO .po-item .po-item-track-section .po-item-track-inner .trackingCard .close {
  position: absolute;
  right: 5px;
  top: 1px;
  cursor: pointer;
  color: cornflowerblue;
}

#pageSupplierPO .po-item .po-item-track-section .po-item-track-inner .trackingCard .close:hover {
  color: rgb(0, 0, 255);
}

#pageSupplierPO .po-item .po-item-track-section .po-item-track-inner .trackingCard .addLink {
  color: blue;
  text-align: center;
  cursor: pointer;
}

#pageSupplierPO .trackingUOM {
  position: relative;
  right: 28px;
}

/* Overlay Acknowledgement stuff */
.supplierPO.overlay-acknowledge {
  background: white;
  padding: 30px;
  text-align: center;
  border: 1px solid var(--content-border-color);
  border-radius: 10px;
  width: 500px;
}

.supplierPO.overlay-acknowledge .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.supplierPO.overlay-acknowledge .instructions {
  margin-bottom: 20px;
}

.supplierPO.overlay-acknowledge .text {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.supplierPO.overlay-acknowledge .text .td {
  text-align: left;
}

.supplierPO.overlay-acknowledge .acknowledgeBtn {
  margin-right: 15px;
}

/* Supplier Save Bar */
#pageSupplierPO .supplierSaveBar {
  background: top left repeat-x url("./saveBarBG.png");
  background-color: lightgray;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 24px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

#pageSupplierPO .supplierSaveBar .supplierSaveBtn {
  cursor: pointer;
  background: top left no-repeat url("./saveButtonBG.png");
  height: 100%;
  padding-top: 4px;
  padding-right: 10px;
  padding-left: 33px;
  color: white;
}

/* Overlay while saving */
#pageSupplierPO .savingOverlay {
  position: fixed;
  background: rgb(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  display: flex;
}

#pageSupplierPO .savingOverlay .statusMsg-outer {
  width: inherit;
  position: relative;
  text-align: center;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
}

#pageSupplierPO .savingOverlay .statusMsg {
  background: white;
  border: 1px solid var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  float: 50%;
}

#pageSupplierPO .savingOverlay .statusMsg .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Overlay for emailing Stellar Buyer */
.supplierPO.overlay-email-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.supplierPO.overlay-email {
  background: white;
  padding: 30px;
  text-align: center;
  border: 1px solid var(--content-border-color);
  border-radius: 10px;
  width: 500px;
  position: relative;
}

.supplierPO.overlay-email .close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 18px;
}

.supplierPO.overlay-email .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.supplierPO.overlay-email .instructions {
  margin-bottom: 20px;
}

.supplierPO.overlay-email .text {
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.5rem;
}

.supplierPO.overlay-email .text input[type="text"] {
  width: 100%;
  margin-bottom: 5px;
}

.supplierPO.overlay-email .text textarea {
  width: 100%;
  height: 300px;
  font-family: Arial, Helvetica, sans-serif;
}

.supplierPO.overlay-email .acknowledgeBtn {
  margin-right: 15px;
}

/*Checkboxes styles*/
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
  top: 2px;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-bottom;
  width: 15px;
  height: 15px;
  background: #b8b8b8;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:hover + label:before {
  background: #b8b8b8;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #b8b8b8;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 7px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.styled-checkbox-important {
  position: absolute;
  opacity: 0;
}
.styled-checkbox-important + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
  top: 2px;
}
.styled-checkbox-important + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-bottom;
  width: 15px;
  height: 15px;
  background: #6ba3f0;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox-important:hover + label:before {
  background: #6ba3f0;
}
.styled-checkbox-important:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox-important:checked + label:before {
  background: #6ba3f0;
}
.styled-checkbox-important:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox-important:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox-important:checked + label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 7px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

/* Button styling */
button {
  border: none;
  background-color: var(--table-odd-row-color);
  color: blue;
  height: 25px;
  width: 100px;
  cursor: pointer;
  border-radius: 3px;
}

.react-confirm-alert-body {
  background: white;
  width: 400px;
  height: 200px;
  text-align: center;
  color: black;
}

.react-confirm-alert-overlay {
  background:rgba(217, 217, 214, 0.9);
}

.react-confirm-alert-button-group > button {
  background-color: #0D6EFD;
  width: 150px;
  height: 2rem;
  margin: .5rem;
  color: white;
}

.saveButton {
  position: absolute;
  right: 10px;
  width: 150px;
  background-color: #28a745;
  color: white;
  top: 10px;
}